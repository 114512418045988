import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import BaseApi from "../api/BaseApi";
import axios from "axios";
import FacebookIcon from '@mui/icons-material/Facebook';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


const Footer = () => {
  const [isQuickLinksVisible, setIsQuickLinksVisible] = useState(false);
  const [isAboutusLinksVisible, setIsAboutusLinksVisible] = useState(false);
  const [isJobseekerLinksVisible, setIsJobseekerLinksVisible] = useState(false);
  const [EndrosecompaniesLinksVisible, setEndrosecompaniesLinksVisible] =
    useState(false);

  let siteTitle = Cookies.get("siteTitle");
  let siteLink = Cookies.get("siteLink");
  let faceboookLink = Cookies.get("fbLink");
  let instagramLink = Cookies.get("instaLink");
  let twitterLink = Cookies.get("twitterLink");
  let pinterestLink = Cookies.get("pinterestLink");
  let linkedInLink = Cookies.get("linkedInLink");
  const [t, i18n] = useTranslation("global");

  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("selectedLanguage") || "en"
  );

  const currentLanguage = Cookies.get("selectedLanguage") || "";

  const [endorsedCompanies, setEndorsedCompanies] = useState([]);

  const getEndorsedCompanies = async () => {
    try {
      const response = await axios.get(BaseApi + "/endorsedcompanies");
      setEndorsedCompanies(response.data.response.top_employer);
    } catch (error) {
      console.log("Error getting endorsed companies!");
    }
  };

  const handleChangeLanguage = (selectedValue) => {
    if (selectedValue) {
      i18n.changeLanguage(selectedValue);
      window.scrollTo(0, 0);
    } else {
      i18n.changeLanguage(currentLanguage);
      window.scrollTo(0, 0);
    }
    window.location.reload();
    setSelectedLanguage(selectedValue);
    Cookies.set("selectedLanguage", selectedValue, { expires: 365 });
  };
  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
      window.scrollTo(0, 0);
    }
  }, []);

  // const [footerName, setFooterName] = useState();
  // const [footerLink, setFooterLink] = useState();

  // const getData = async () => {
  //   try {
  //     const response = await axios.get(BaseApi + "/getconstant");
  //     setFooterLink(response.data.response.site_link);
  //     setFooterName(response.data.response.site_title);
  //   } catch (error) {
  //     console.log("Error getting navbar logo information!");
  //   }
  // };
  useEffect(() => {
    // getData();
    // window.scrollTo(0, 0);
    getEndorsedCompanies();
  }, []);

  const toggle3 = () => {
    setIsQuickLinksVisible(!isQuickLinksVisible);
  };
  const toggle2 = () => {
    setIsAboutusLinksVisible(!isAboutusLinksVisible);
  };
  const toggle1 = () => {
    setIsJobseekerLinksVisible(!isJobseekerLinksVisible);
  };
  const toggle4 = () => {
    setEndrosecompaniesLinksVisible(!EndrosecompaniesLinksVisible);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setIsQuickLinksVisible(false);
        setIsAboutusLinksVisible(false);
        setIsJobseekerLinksVisible(false);
      } else {
        setIsQuickLinksVisible(false);
        setIsAboutusLinksVisible(false);
        setIsJobseekerLinksVisible(true);
      }
    };

    // Call the function on component mount
    handleWindowResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // getData();
  }, []);

  let screenWidth = window.innerWidth;

  return (
    <>
      {screenWidth > 768 ? (
        <>
          <footer>
            <div className="container">
              <div className="">
                {/* <div className="col-md-12 text-center bottom-lines">
                  <img
                    src="/Images/footer-lines.png"
                    className="img-responsive d-inline-block"
                  />
                </div> */}
              </div>
              <div className="row">
                <div className="col-md-8">
                  <ul className="nav footer-nav">
                    {/* <li>
                      <Link to="">Home</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/jobseeker-registration-information">
                        JOB SEEKERS
                      </Link>
                    </li> */}
                    <li class="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        JOB SEEKERS <span class="caret"></span>
                      </Link>
                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/new-to-first-nations"
                          >
                            New to First Nations Jobs?
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/employerlogin"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/register/employer"
                          >
                            Register
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            className="dropdown-item"
                            to="/user/register/jobseeker"
                          >
                            Register online
                          </Link>
                        </li> */}
                        <li>
                          <Link className="dropdown-item" to="/searchjob">
                            Job Search
                          </Link>
                        </li>
                      </ul>
                    </li>

                    {/* <li class="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Job Seekers <span class="caret"></span>
                      </Link>
                      <ul class="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="/newsearchjob">
                            Search jobs
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/jobseekerlogin"
                          >
                            Jobseeker login
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/alerts/add">
                            Create job alert
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/candidates/editExperience"
                          >
                            Experience
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/candidates/editEducation"
                          >
                            Education
                          </Link>
                        </li>
                      </ul>
                    </li> */}

                    <li class="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle "
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Employers <span class="caret"></span>
                      </Link>
                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/why-first-nations"
                          >
                            Why First Nation jobs?
                          </Link>
                        </li>
                        {/* <li>
                          <Link className="dropdown-item" to="/services">
                            Services
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/employerlogin"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/register/employer"
                          >
                            Register
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li class="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Endorsed Companies <span class="caret"></span>
                      </Link>
                      <ul class="dropdown-menu">
                       
                        {endorsedCompanies?.map((i) => {
                          return (
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/companyprofile/${i.slug}`}
                              >
                                {i.company_name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li> */}
                    {/* <li class="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Contact <span class="caret"></span>
                      </Link>
                      
                      <ul class="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="/about-us">
                          
                            About Us
                          </Link>
                        </li>
                       
                        <li>
                          <Link className="dropdown-item" to="/contact">
                            Contact US
                          </Link>
                        </li>

                      </ul>
                    </li> */}
                    {/* <li class="nav-item">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Contact
                      </Link>
                      </li> */}
                      <li>
                          <Link className="" to="/contact">
                          
                          Contact
                          </Link>
                        </li>
                      <li>
                          <Link className="" to="/about-us">
                            {/* Leadership Team */}
                            About Us
                          </Link>
                        </li>

                    {/* <li class="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        ENDORSED COMPANIES <span class="caret"></span>
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="">
                            Maddocks
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="">
                            Swinburne University
                          </Link>
                        </li>
                      </ul>
                    </li> */}

                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        CONTACT US <span class="caret"></span>
                      </Link>
                      <ul class="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="/aboutus">
                            About us
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/contact">
                            Contact us
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                  <hr />

                  <ul className="sub-line">
                    {/* <li>
                      <Link to="/contact">Contact us</Link>
                    </li> */}
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={faceboookLink} target="_blank">
                        {/* <i class="fa fa-brands fa-square-facebook"></i> */}
                        <FacebookIcon />
                      </Link>
                    </li>

                    {/* <li>
                      <Link to={twitterLink} target="_blank">
                        <i class="fa fa-twitter-square"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="col-md-4 text-right ">
                  <Link
                    to="https://ibd.supplynation.org.au/public/s/supplierprofile?accid=a1GOd000003ANPpMAO"
                    target="_blank"
                  >
                    <img
                      src="/Images/SN_Registered_ART.png"
                      alt=""
                      className=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        <>
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <ul className="nav footer-nav d-block">
                    <div className="FooterListLinks " onClick={toggle1}>
                      <div className="footerEachHeader">
                        <h3>JOB SEEKER </h3>
                        <Link className="footerPlusLink" onClick={toggle1}>
                          {isJobseekerLinksVisible ? (
                            // <i className="fa-solid fa-circle-minus"></i>
                            <i class="fa fa-minus-circle" aria-hidden="true"></i>
                          ) : (
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            // <i className="fa-solid fa-circle-plus"></i>
                          )}
                        </Link>
                      </div>

                      <ul
                        style={{
                          display: isJobseekerLinksVisible ? "block" : "none",
                        }}
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/new-to-first-nations"
                          >
                            New to First Nations Jobs?
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            className="dropdown-item"
                            to="/user/register/jobseeker"
                          >
                            Register online
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/employerlogin"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/register/employer"
                          >
                            Register
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/searchjob">
                            Job Search
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="FooterListLinks " onClick={toggle2}>
                      <div className="footerEachHeader">
                        <h3>EMPLOYERS </h3>
                        <Link className="footerPlusLink" onClick={toggle2}>
                          {isAboutusLinksVisible ? (
                           <i>
                              
                           <RemoveCircleIcon />
                           </i>
                          ) : (
                            <i>
                              <AddCircleIcon />
                              
                            </i>
                          )}
                        </Link>
                      </div>

                      <ul
                        style={{
                          display: isAboutusLinksVisible ? "block" : "none",
                        }}
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/why-first-nations"
                          >
                            Why First Nation jobs?
                          </Link>
                        </li>
                        {/* <li>
                          <Link className="dropdown-item" to="/services">
                            Services
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/employerlogin"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/register/employer"
                          >
                            Register
                          </Link>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="FooterListLinks" onClick={toggle2}>
                      <div className="footersubLinks">
                        <h3>ENDORSED COMPANIES</h3>
                        <Link className="footerAddicon">
                          {isAboutusLinksVisible ? (
                            <i className="fa-solid fa-circle-minus"></i>
                          ) : (
                            <i className="fa-solid fa-circle-plus"></i>
                          )}
                        </Link>
                      </div>

                      <ul
                        style={{
                          display: isAboutusLinksVisible ? "block" : "none",
                        }}
                      >
                        <li>
                          <Link className="dropdown-item" to="">
                            Maddocks
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="">
                            Swinburne University
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                    {/* <li>
                      <Link
                        to="/contact"
                        style={{ fontSize: "17px" }}
                      >
                        CONTACT
                      </Link>
                    </li> */}

                    {/* <div className="FooterLinks" onClick={toggle3}>
                      <div className="footerEachHeader">
                        <h3>ENDROSE COMPANIES</h3>
                        <Link className="footerPlusLink">
                          {isQuickLinksVisible ? (
                            <i className="fa-solid fa-circle-minus"></i>
                          ) : (
                            <i className="fa-solid fa-circle-plus"></i>
                          )}
                        </Link>
                      </div>

                      <ul
                        style={{
                          display: isQuickLinksVisible ? "block" : "none",
                        }}
                      >
                        {endorsedCompanies?.map((i) => {
                          return (
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/companyprofile/${i.slug}`}
                              >
                                {i.company_name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div> */}
                    <div className="FooterLinks" onClick={toggle4}>
                      <div className="footerEachHeader">
                        <h3>CONTACT</h3>
                        <Link className="footerPlusLink">
                          {EndrosecompaniesLinksVisible ? (
                           <i class="fa fa-minus-circle" aria-hidden="true"></i>
                             
                          ) : (
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                          )}
                        </Link>
                      </div>

                      <ul
                        style={{
                          display: EndrosecompaniesLinksVisible
                            ? "block"
                            : "none",
                        }}
                      >
                        <li>
                          <Link className="dropdown-item" to="/about-us">
                            ABOUT US
                          </Link>
                        </li>

                        {/* <li>
                          <Link className="dropdown-item" to="">
                            Adviory Board
                          </Link>
                        </li> */}
                        <li>
                          <Link className="dropdown-item" to="/contact">
                            Contact us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </ul>
                  <hr />

                  <ul className="sub-line">
                    {/* <li>
                      <Link to="/contact">Contact us</Link>
                    </li> */}
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={faceboookLink} target="_blank">
                        {/* <i class="fa  fa-facebook-official"></i> */}
                        <FacebookIcon />
                      </Link>
                    </li>

                    {/* <li>
                      <Link to={twitterLink} target="_blank">
                        <i class="fa fa-twitter-square"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                {/* <div className="col-md-4 text-right d-none">
                  <img src="./logo.png" alt="" />
                </div> */}
                <div className="col-md-4 text-right d-flex justify-content-end ">
                  <Link
                    to="https://ibd.supplynation.org.au/public/s/supplierprofile?accid=a1GOd000003ANPpMAO"
                    target="_blank"
                  >
                    <img
                      src="/Images/SN_Registered_ART.png"
                      alt=""
                      className=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default Footer;
