import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APNavBar from "../Elements/APNavBar";
import APSidebar from "../APSidebar/APSidebar";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import BaseApi from "../../api/BaseApi";
import ApiKey from "../../api/ApiKey";
import Swal from "sweetalert2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";
// import AddIcon from "@mui/icons-material/Add";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, IconButton } from "@mui/material";
import Cookies from "js-cookie";
import APFooter from "../Elements/APFooter";
import { DataGrid } from "@mui/x-data-grid";

const APJobseekerList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const tokenKey = Cookies.get("token");
  const adminID = parseInt(Cookies.get("adminID"));

  const [currentPage, setCurrentPage] = useState(1);
  // const [dataPerPage, setDataPerPage] = useState([]);
  const itemsPerPage = 20;
  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  // const [selectedPlan, setSelectedPlan] = useState(null);
  const handleOpen = (plan) => {
    setSelectedPayment(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPayment(null);
    setOpen(false);
  };

  const [userAccess, setUserAccess] = useState({});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjusted width for mobile responsiveness
    maxWidth: "400px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/admin/candidates/index",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
          },
        }
      );
      setLoading(false);
      setListData(response.data.response.user_array);
      //   console.log(paymentHistory);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get plans data at APmanageplans");
    }
  };
  const handleActivate = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Activate Jobseeker?",
        text: "Do you want to Activate this Jobseeker?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        // setLoading(true);
        const response = await axios.post(
          BaseApi + `/admin/candidates/activateuser/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Jobseeker Activated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Activate Jobseeker",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't activate the record!", error.message);
    }
  };
  const handleDeactivate = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Deactivate Jobseeker?",
        text: "Do you want to Deactivate this Jobseeker?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        // setLoading(true);
        const response = await axios.post(
          BaseApi + `/admin/candidates/deactivateuser/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Jobseeker Deactivated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Deactivate Jobseeker",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't deactivate the record!", error.message);
    }
  };

  const handleDelete = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Jobseeker?",
        text: "Do you want to Delete this Jobseeker?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        // setLoading(true);
        const response = await axios.post(
          BaseApi + `/admin/candidates/deleteusers/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Jobseeker deleted successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Delete Jobseeker",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't delete the record!", error.message);
    }
  };

  const [selectAll, setSelectAll] = useState(true);

  // const handleCheck = (id) => {
  //   // Check if the id is 'selectAll', indicating the "Select All" checkbox
  //   if (id === "selectAll") {
  //     // If it's "Select All", toggle the selectAll state
  //     setSelectAll(!selectAll);

  //     // If selectAll is true, select all checkboxes, else unselect all
  //     const updatedCheckedData = selectAll
  //       ? listData.map((item) => item.id)
  //       : [];
  //     setCheckedData(updatedCheckedData);
  //   } else {
  //     // Individual checkbox handling remains the same
  //     if (checkedData.includes(id)) {
  //       // If the ID is already in the array, remove it
  //       setCheckedData(checkedData.filter((checkedId) => checkedId !== id));
  //     } else {
  //       // If the ID is not in the array, add it
  //       setCheckedData([...checkedData, id]);
  //     }
  //   }
  // };

  const handleCheck = (id) => {
    // Check if the id is 'selectAll', indicating the "Select All" checkbox
    if (id === "selectAll") {
      // If it's "Select All", toggle the selectAll state
      const updatedCheckedData =
        checkedData.length === currentItems.length
          ? [] // If all are checked, uncheck all
          : currentItems.map((item) => item.id); // Otherwise, check all on the current page
      setCheckedData(updatedCheckedData);
    } else {
      // Individual checkbox handling remains the same
      if (checkedData.includes(id)) {
        // If the ID is already in the array, remove it
        setCheckedData(checkedData.filter((checkedId) => checkedId !== id));
      } else {
        // If the ID is not in the array, add it
        setCheckedData([...checkedData, id]);
      }
    }
  };

  // const handleCheck = (id) => {
  //   if (checkedData.includes(id)) {
  //     // If the ID is already in the array, remove it
  //     setCheckedData(checkedData.filter((checkedId) => checkedId !== id));
  //   } else {
  //     // If the ID is not in the array, add it
  //     setCheckedData([...checkedData, id]);
  //   }
  // };

  const handleMultipleDeactivate = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Deactivate Jobseekers?",
        text: "Do you want to Deactivate these Jobseekers?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const ids = checkedData.join(",");
        console.log(ids);
        // setLoading(true);
        const response = await axios.post(
          BaseApi + "/admin/candidates/index",
          {
            idList: ids,
            action: "deactivate",
          }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        setSelectAll(true);
        setCheckedData("");
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Jobseekers Deactivated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: "Couldn't Deactivate!",
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Deactivate Jobseekers",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't deactivate the record!", error.message);
    }
  };
  const handleMultipleActivate = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Activate Jobseekers?",
        text: "Do you want to Activate these Jobseekers?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        let ids = checkedData.toString();
        console.log(ids);
        // setLoading(true);
        const response = await axios.post(
          BaseApi + "/admin/candidates/index",
          {
            idList: ids,
            action: "activate",
          }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        setSelectAll(true);
        setCheckedData("");
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Jobseekers Activated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: "Couldn't Activate!",
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Activate Jobseekers",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't activate the record!", error.message);
    }
  };
  const handleMultipleDelete = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Jobseekers?",
        text: "Do you want to Delete these Jobseekers?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        let ids = checkedData.toString();
        console.log(ids);
        // setLoading(true);
        const response = await axios.post(
          BaseApi + "/admin/candidates/index",
          {
            idList: ids,
            action: "delete",
          }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        setSelectAll(true);
        setCheckedData("");
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Jobseekers Deleted successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: "Couldn't Delete!",
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Delete Jobseekers",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't Delete the record!", error.message);
    }
  };

  const sortAndFilterData = (array, key, direction, query) => {
    const sortedAndFilteredArray = [...array]
      .filter(
        (item) =>
          item.first_name?.toLowerCase().includes(query) ||
          item.email_address?.toLowerCase().includes(query) ||
          item.contact?.toLowerCase().includes(query) ||
          item.location?.toLowerCase().includes(query) ||
          item.created?.toLowerCase().includes(query)
      )
      .sort((a, b) => {
        if (key === "created") {
          // Convert created to Date objects for comparison
          const dateA = new Date(a[key]);
          const dateB = new Date(b[key]);
          if (dateA < dateB) {
            return direction === "ascending" ? -1 : 1;
          }
          if (dateA > dateB) {
            return direction === "ascending" ? 1 : -1;
          }
          return 0;
        } else {
          // Handle other keys
          if (a[key] < b[key]) {
            return direction === "ascending" ? -1 : 1;
          }
          if (a[key] > b[key]) {
            return direction === "ascending" ? 1 : -1;
          }
          return 0;
        }
      });
    return sortedAndFilteredArray;
  };

  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });

  // const sortAndFilterData = (array, key, direction, query) => {
  //   const sortedAndFilteredArray = [...array]
  //     .filter(
  //       (item) =>
  //         item.first_name?.toLowerCase().includes(query) ||
  //         item.email_address?.toLowerCase().includes(query) ||
  //         item.contact?.toLowerCase().includes(query) ||
  //         item.location?.toLowerCase().includes(query) ||
  //         item.created?.toLowerCase().includes(query)
  //     )
  //     .sort((a, b) => {
  //       if (a[key] < b[key]) {
  //         return direction === "ascending" ? -1 : 1;
  //       }
  //       if (a[key] > b[key]) {
  //         return direction === "ascending" ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //   return sortedAndFilteredArray;
  // };
  // const [sortConfig, setSortConfig] = useState({
  //   key: "",
  //   direction: "ascending",
  // });
  const [symbol, setSymbol] = useState("🔺");

  const sortedAndFilteredData = sortAndFilterData(
    listData,
    sortConfig.key,
    sortConfig.direction,
    searchQuery
  );

  const filteredData = listData.filter((item) => {
    const searchString = searchQuery.toLowerCase();
    return (
      item.first_name?.toLowerCase().includes(searchString) ||
      item.email_address?.toLowerCase().includes(searchString) ||
      item.contact?.toLowerCase().includes(searchString) ||
      item.location?.toLowerCase().includes(searchString) ||
      item.created?.toLowerCase().includes(searchString)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedAndFilteredData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(sortedAndFilteredData.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/admin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage to 1 when searchQuery changes
  }, [searchQuery]);

  const handleColumnClick = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
      setSymbol("🔻");
    } else {
      setSymbol("🔺");
    }
    setSortConfig({ key, direction });
  };
  const handleSearchChange = (e) => {
    const newQuery = e.target.value.toLowerCase();
    setSearchQuery(newQuery);
  };

  const handleExport = () => {
    // Replace 'your-file-url' with the actual URL of the file you want to download
    const fileUrl = BaseApi + "/admin/candidates/generatecsv";

    // Create an invisible <a> element
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = fileUrl;

    // Set the download attribute to specify the file name (optional)
    link.download = "Export List";

    // Add the <a> element to the DOM
    document.body.appendChild(link);

    // Trigger a click event on the <a> element to initiate the download
    link.click();
  };

  useEffect(() => {
    const access = Cookies.get("access");

    if (typeof access !== null || access !== "" || access !== undefined) {
      console.log(JSON.parse(access));

      setUserAccess(JSON.parse(access));
    } else {
      setUserAccess({});
    }
  }, []);

  const rows = currentItems.map((item) => ({
    id: item.id,
    fullName: item.first_name + " " + item.last_name,
    first_name: item.first_name,
    last_name: item.last_name,
    email_address: item.email_address,
    contact: item.contact ? item.contact : "N/A",
    location: item.location ? item.location : "N/A",
    created: item.created,
    status: item.status,
    slug: item.slug,
  }));

  const columns = [
    { field: "fullName", headerName: "Full Name", width: 200 },
    { field: "email_address", headerName: "Email", width: 250 },
    { field: "contact", headerName: "Contact Number", width: 150 },
    { field: "location", headerName: "Location", width: 120 },
    { field: "created", headerName: "Created", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <div className="APActionButton">
          {params.row.status === 1 ? (
            <button
              className="btn btn-secondary"
              onClick={() => handleDeactivate(params.row.slug)}
            >
              <Tooltip title="Deactivate">
                <CheckIcon />
              </Tooltip>
            </button>
          ) : (
            <button
              className="btn btn-secondary"
              onClick={() => handleActivate(params.row.slug)}
            >
              <Tooltip title="Activate">
                <BlockIcon />
              </Tooltip>
            </button>
          )}

          {(userAccess[2]?.Edit === 1 || adminID === 1) && (
            <>
              <button
                className="btn btn-primary"
                onClick={() =>
                  navigate(`/admin/candidates/editcandidates/${params.row.slug}`)
                }
              >
                <Tooltip title="Edit">
                  <CreateIcon />
                </Tooltip>
              </button>
            </>
          )}

          {(userAccess[2]?.Delete === 1 || adminID === 1) && (
            <>
              <button
                className="btn btn-secondary"
                onClick={() => handleDelete(params.row.slug)}
              >
                <Tooltip title="Delete">
                  <DeleteIcon />
                </Tooltip>
              </button>
            </>
          )}

          <button className="btn btn-primary" onClick={() => handleOpen(params.row)}>
            <Tooltip title="View">
              <RemoveRedEyeIcon />
            </Tooltip>
          </button>

          <button
            className="btn btn-primary"
            onClick={() => navigate(`/admin/candidates/certificates/${params.row.slug}`)}
          >
            <Tooltip title="Manage Certificate">
              <ContentCopyIcon />
            </Tooltip>
          </button>
          <button
            className="btn btn-primary"
            onClick={() => navigate(`/admin/jobs/applied/${params.row.slug}`)}
          >
            <Tooltip title="Applied Job List">
              <FormatListBulletedIcon />
            </Tooltip>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <APNavBar />
      <div className="APBasic APManageSubadmin APJobseekerList">
        <APSidebar />

        {loading ? (
          <>
            <div className="loader-container"></div>
          </>
        ) : (
          <>
            <div className="site-min-height">
              <div className="breadCumb1" role="presentation">
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon fontSize="small" />}
                >
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate("/admin/admins/dashboard")}
                  >
                    Dashboard
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate("")}
                  >
                    Jobseekers
                  </Link>

                  <Typography color="text.primary">Jobseeker List</Typography>
                </Breadcrumbs>
              </div>
              <div className="ManageSubAdminHeader">
                <h2 className="">Jobseeker List</h2>
                <button
                  className="btn navButton1 APMSbutton"
                  onClick={() => handleExport()}
                >
                  Export Jobseeker
                </button>
                {(userAccess[2]?.Add === 1 || adminID === 1) && (
                  <>
                    <button
                      className="btn navButton1 APMSbutton"
                      onClick={() =>
                        navigate("/admin/candidates/addcandidates")
                      }
                    >
                      Add Jobseeker
                    </button>
                  </>
                )}
              </div>
              {listData != "" ? (
                <>
                  <div className="manageSubadminPart1">
                    <form>
                      <div className="manageSubadminPart1Sec1">
                        <h4>
                          Search jobseeker by typing Name, email or Created date
                        </h4>
                        <div class="APDashboardSearchBx ">
                          {/* <label for="formFile" class="form-label">
                          Search By Keyword
                        </label> */}

                          <input
                            type="text"
                            id="form3Example1"
                            className="form-control"
                            name="searchQuery"
                            placeholder="Search.."
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </div>
                      </div>
                    </form>
                    <h4>
                      No. of Results {indexOfFirstItem + 1}-
                      {Math.min(
                        indexOfLastItem,
                        filteredData.length || listData.length
                      )}{" "}
                      of {listData.length}
                    </h4>
                  </div>

                  <div className="manageSubadminPart2">
                    <table class="table">
                      <div className="jobTable">
                        <Box sx={{ height: "100%", width: "100%" }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{ pagination: { listData } }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection
                            sx={{ border: 0 }}
                            onRowSelectionModelChange={(
                              newRowSelectionModel
                            ) => {
                              setCheckedData(newRowSelectionModel);
                            }}
                            rowSelectionModel={checkedData}
                            {...listData}
                          />
                        </Box>
                      </div>

                      {/* <thead>
                        <tr>
                          <td className="checkBoxCol checkBxHead">
                            <input
                              type="checkbox"
                              className="tableCheckBox"
                              // checked={selectAll}

                              onClick={() => handleCheck("selectAll")}
                            />
                          </td>
                          <th onClick={() => handleColumnClick("first_name")}>
                            Full Name {symbol}
                          </th>
                          <th
                            onClick={() => handleColumnClick("email_address")}
                          >
                            Email {symbol}
                          </th>

                          <th onClick={() => handleColumnClick("contact")}>
                            Contact Number {symbol}
                          </th>
                          <th onClick={() => handleColumnClick("location")}>
                            Location {symbol}
                          </th>

                          <th onClick={() => handleColumnClick("created")}>
                            <CalendarMonthIcon /> Created {symbol}
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead> */}
                      {/* <tbody>
                        {currentItems.map((i) => {
                          return (
                            <>
                              <tr>
                                <td className="checkBoxCol">
                                  <td className="checkBoxCol">
                                    <input
                                      type="checkbox"
                                      className="tableCheckBox"
                                      checked={checkedData.includes(i.id)} // Check if the current ID is in checkedData
                                      onChange={() => handleCheck(i.id)} // Pass the ID to the handler
                                    />
                                  </td>
                                </td>
                                <td>
                                  {i.first_name} {i.last_name}
                                </td>
                                <td>{i.email_address}</td>
                                <td>{i.contact}</td>
                                <td>{i.location}</td>
                                <td>{i.created}</td>
                                <td className="APActionButton">
                                  {i.status === 1 ? (
                                    <button
                                      className="btn btn-secondary"
                                      onClick={() => handleDeactivate(i.slug)}
                                    >
                                      <Tooltip title="Deactivate">
                                        <CheckIcon />
                                      </Tooltip>
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-secondary"
                                      onClick={() => handleActivate(i.slug)}
                                    >
                                      <Tooltip title="Activate">
                                        <BlockIcon />
                                      </Tooltip>
                                    </button>
                                  )}

                                  {(userAccess[2]?.Edit === 1 ||
                                    adminID === 1) && (
                                    <>
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          navigate(
                                            `/admin/candidates/editcandidates/${i.slug}`
                                          )
                                        }
                                      >
                                        <Tooltip title="Edit">
                                          <CreateIcon />
                                        </Tooltip>
                                      </button>
                                    </>
                                  )}

                                  {(userAccess[2]?.Delete === 1 ||
                                    adminID === 1) && (
                                    <>
                                      <button
                                        className="btn btn-secondary"
                                        onClick={() => handleDelete(i.slug)}
                                      >
                                        <Tooltip title="Delete">
                                          <DeleteIcon />
                                        </Tooltip>
                                      </button>
                                    </>
                                  )}

                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleOpen(i)}
                                  >
                                    <Tooltip title="View">
                                      <RemoveRedEyeIcon />
                                    </Tooltip>
                                  </button>

                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      navigate(
                                        `/admin/candidates/certificates/${i.slug}`
                                      )
                                    }
                                  >
                                    <Tooltip title="Manage Certificate">
                                      <ContentCopyIcon />
                                    </Tooltip>
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      navigate(`/admin/jobs/applied/${i.slug}`)
                                    }
                                  >
                                    <Tooltip title="Applied Job List">
                                      <FormatListBulletedIcon />
                                    </Tooltip>
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody> */}
                    </table>

                    <div>
                      <Modal
                        className="modalMain"
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box className="modal adminModal modal-content">
                          <IconButton
                            onClick={handleClose}
                            className="close-button"
                            style={{
                              position: "absolute",
                              top: 10,
                              right: 10,
                            }}
                          >
                            &times;
                          </IconButton>

                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          ></Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 3 }}
                          >
                            {selectedPayment && (
                              <div className="modals ">
                                <div className="modalHead">
                                  <h1>
                                    {selectedPayment.first_name}{" "}
                                    {selectedPayment.last_name}
                                  </h1>
                                </div>
                                <div className="modalBody mt-4">
                                  <div className="modalParent">
                                    <div className="modalLeft">
                                      First Name:{" "}
                                    </div>
                                    <div className="modalRight">
                                      {selectedPayment.first_name}
                                    </div>
                                  </div>

                                  <div className="modalParent">
                                    <div className="modalLeft">Last Name: </div>
                                    <div className="modalRight">
                                      {selectedPayment.last_name}
                                    </div>
                                  </div>

                                  <div className="modalParent">
                                    <div className="modalLeft">
                                      Email Address:{" "}
                                    </div>
                                    <div className="modalRight">
                                      {selectedPayment.email_address}
                                    </div>
                                  </div>
                                  <div className="modalParent">
                                    <div className="modalLeft">Location: </div>
                                    <div className="modalRight">
                                      {selectedPayment.location}
                                    </div>
                                  </div>

                                  <div className="modalParent">
                                    <div className="modalLeft">
                                      Contact Number:{" "}
                                    </div>
                                    <div className="modalRight">
                                      {selectedPayment.contact}
                                    </div>
                                  </div>

                                  <div className="modalParent">
                                    <div className="modalLeft">Video: </div>
                                    <div className="modalRight">
                                      {selectedPayment.video}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Typography>
                        </Box>
                      </Modal>
                    </div>
                  </div>
                  <div className="manageSubadminpart3">
                    <div className="functionalityButton">
                      <button
                        type="button"
                        className="btn btn-primary APButton3"
                        onClick={() => handleMultipleActivate()}
                      >
                        ACTIVATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary APButton3"
                        onClick={() => handleMultipleDeactivate()}
                      >
                        DEACTIVATE
                      </button>
                      {(userAccess[2]?.Delete === 1 || adminID === 1) && (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary APButton4"
                            onClick={() => handleMultipleDelete()}
                          >
                            DELETE
                          </button>
                        </>
                      )}
                    </div>

                    <div className="pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="primary"
                          page={currentPage}
                          onChange={(event, page) => setCurrentPage(page)}
                        />
                      </Stack>
                    </div>
                  </div>
                </>
              ) : (
                <div className="appliedJobListNoData mt-5">
                  No Records Found
                </div>
              )}
            </div>
            <APFooter />
          </>
        )}
      </div>
    </>
  );
};

export default APJobseekerList;
